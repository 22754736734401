.arcoiris {
  /* color: #7366ff; */
  background-color: #46AFE5;


}

.lab {
  color: #FFF;
}

.tdd {
  background-color: #46AFE5;

}

.ing {
  color: rgb(65, 39, 218);
}

.can {
  color: rgb(255, 10, 10);
}


.icon-color-Company {
  color: #46AFE5;
}

.labelEmitos{
  color: #0079C7;
}

.labelEgress{
  color: #46AFE6;
}

.labelIngress{
  color: #0DC356;
}

.labelCancellation{
  color: #033d5b !important;
}

.cardBox {
  box-shadow:  0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.bangeColor{
  background-color: #033d5b !important;
  color: #FFF !important;
}

.typeInvoicelabel {
   align-items: center;
    background-color: #033d5b !important;
  
    border-radius: 50% !important;
    color: #fff;
    cursor: pointer;
    display: flex;
    height: 42px;
    justify-content: center;
    margin-right: 15px;
    width: 42px;

}

.InvalidtypeInvoicelabel {
  align-items: center;
   background-color: #757575 !important;
   border-radius: 50% !important;
   color: #fff;
   cursor: pointer;
   display: flex;
   height: 42px;
   justify-content: center;
   margin-right: 15px;
   width: 42px;
}

.typeInvoiBranch {
  align-items: center;
  background-color: #033d5b !important;
  border-radius: 50% !important;
  color: #fff;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  margin-right: 15px;
  width: 32px;
}

.typeInvoiBranch2 {
  align-items: center;
   background-color: #757575 !important;
   border-radius: 50% !important;
   color: #fff;
   cursor: pointer;
   display: flex;
   height: 32px;
   justify-content: center;
   margin-right: 15px;
   width: 32px;
}




.labelConcept {
  color: #033d5b !important;
}

.typeCompany {
  color: rgb(63 82 91 / 73%) !important;
}


.thead-Company{
  color: #fff !important;
  background-color: rgb(32 136 203 / 72%) !important;
  border-color: #454d55;
}


.typeParticular {
  color: rgba(164, 52, 244, 0.73) !important;
}

.type-Badge-Particular {
  background-color: rgb(86 24 129 / 16%) !important;
  color: blueviolet !important;
}

.btn-gradient-Particular {
  color: #fff !important;
  font-weight: 500;
  padding: 10px 30px;
  border-radius: 5px;
  background: linear-gradient(26deg, #9836eae0 0%, #0079C7 100%);
  letter-spacing: 0.06rem;
}

.ribbon-secondary-Particular{
  background: #9086c9 !important;
}


.progress-gradient-info-Patient {
  background-image: linear-gradient(to right, transparent, #cd98ff) !important;
  position: relative;
}

.thead-Particular{

  color: #fff !important;
  background-color: #b09ce3;
  border-color: #454d55;
}

.InvalidtypeCash {
  align-items: center;
  background-color: #75757587 !important;
   border-radius: 50% !important;
   color: #fff;
   cursor: pointer;
   display: flex;
   height: 42px;
   justify-content: center;
   margin-right: 15px;
   width: 42px;

}

.badge-public-general {
  background: #d91e1e91 !important;

}

.badge-efectivo{
  background: #9086c9 !important;
}

.badge-prefactura{
  background: #f8d62b !important;
}


.badge-public-general {
  background: #d91e1e91 !important;

}

.badge-efectivo{
  background: #9086c9 !important;
}

.badge-prefactura{
  background: #f8d62b !important;
}

.type-Badge-Particular-not-invoice {
  background-color: #dc35452b !important;
  color: #ff0018 !important;
}

.type-Badge-Particular-invoice {
  background-color: #0029402b !important;
  color: #033d5b !important;
}


.card-Information {
  background-color: #46AFE5 !important;
  border-radius:22px;
  height: 158px !important;
}

.label-text-Information{
  color: #FFF !important;
}

.type-Card-invoice-Backing {
  background-color: #b71515d9 !important;
}

.type-Card-invoice-Backing-patient {
  background-color: #15b738d9 !important;
}

.type-Card-invoice-Amount {
  background-color: #0079C7 !important;
}

.shadowInvoice {
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.3) !important;
}


.btn-gradient-danger {
  color: #fff !important;
  font-weight: 500;
  padding: 10px 30px;
  border-radius: 5px;
  background: linear-gradient(26deg, #dc3545 0%, #dc3545 100%);
  letter-spacing: 0.06rem;
}



.react-calendar {
  font-family: Arial, sans-serif;
  max-width: 400px;
  margin: 0 auto;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.3) !important;
  line-height: 2.125em !important;
}


/* Estilo para los días del mes */
.react-calendar__tile {
  flex: 1 0 calc(100% / 7);
  padding: 8px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 12px !important;
}


/* Estilo para los días de la semana */
.react-calendar__month-view__weekdays {
  color: #0079C7 !important;
  font-size: 12px !important;
}

.notification-invoice{
  color: #51bb25 !important;
}

.notification-invoice-not{
  color: #dc3545 !important;
}


.selected-valid {
  background-color: #51bb25 !important;
  color: #fff !important;
}

/* Estilo para la fecha seleccionada sin notificación válida */
.selected-invalid {
  background-color: #dc3545 !important;
  color: #fff !important;
}

.cardFully {
  background: linear-gradient(45deg, #1f9ad9, transparent);
}

.labelFullyPaid {
  color: #FFF;
}

.progress-gradient-primary-fully-paid {
  background-image: linear-gradient(to right, transparent, #494ba9);
  position: relative;
}

.customizer-links .nav-link span {
  position: absolute;
  right: -60px;
  width: max-content;
  text-align: right;
  padding: 7px 10px;
  display: block;
  top: 0;
  border-radius: 5px;
  transform: scale(0);
  transition: all 0.3s ease;
  opacity: 0;
  color: #FFFF !important;
  background-color: #1f9ad9 !important;
  box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
}


.generalPublic {
  color:rgba(217,30,30,.5686274509803921)!important;
}

.labelBadgeInvoice {
  font-size: 11px !important;
}

.file-top-1 {
  height: 100px;
  background-color: #fff;
  border: 1px solid #ececec;
  border-radius: 5px;
  font-size: 1px !important;
  /* display: flex; */
  align-items: center;
  justify-content: center;
}

.display-invoice {
  position: absolute !important;
  top: 100% !important;
  left: 30% !important;
}

.divkkk{
  position: relative !important;
      display: inline-block !important;
}


.file-boxI {
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  padding: 15px;
  background-color: #f6f7fb;
  width: calc(260px - 15px) !important;
  display: inline-block;
  position: relative;
}

.filter-invoices{
  filter: blur(1px) !important;

}


 .card-headerI {
  padding: 2px !important;
}

.labelInvoiceR{
  color: rgba(217,30,30,.5686274509803921)!important
}

.badge-secondaryInvoice {
  background-color: rgba(217,30,30,.5686274509803921)!important
}


.border-top-deposits {
  border-top: 1px solid #ecf3fa !important;
  padding: 10px !important;
}

.label-invoice-Indications{
  font-size: 15px !important;
  font-weight: 500 !important;
}

.earning-card-invoice {
  padding: 10px 0 40px 40px !important;
}

.efectivo {
  background-color: #86fa5645;
  color: rgb(17, 255, 0);
  font-size: 11px;
  font-weight: 610 !important;
}

.tarjeta-credito {
  background-color: #f6cd003b;
  color: rgb(255, 60, 0);
  font-size: 11px;
  font-weight: 610 !important;
}

.tarjeta-debito {
  color:rgb(255, 217, 0);
  background-color: #f0f8fd;
  font-size: 11px;
  font-weight: 610 !important;

}
.credito-pay {
  color:rgb(255, 0, 0);
  background-color: #f0f8fd;
  font-size: 11px;
  font-weight: 610 !important;

}

.transferencia-electronica {
  background-color: #f0f8fd;
  color: rgb(140, 0, 255) !important;
  font-size: 11px;
  font-weight: 610 !important;

}

.metodo-ppd{
  color:#fc6271d1;
  background-color: #dc354512;
  font-size: 11px;
  font-weight: 610 !important;

}


.label-branch-report{
  font-weight: 610 !important;
  font-size: 12px;
}

.card-patient-invoice {
  background-color: #1f9ad9;
}


.media-invoice {
  background-color: #dc3545  !important;
}


.paypal-method {
  background-color: #18346224;
  color: #183462;; 
  font-size: 12px;
  font-weight: 610 !important;
}

.table-wrapper {
  width: 100%;
  height: 400px; /* Altura de ejemplo */
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #00294094 rgba(0, 0, 0, 0);
}

.table-wrapper table thead {
  position: -webkit-sticky; /* Safari... */
  position: sticky;
  top: 0;
  left: 0;
}
.sticky-footer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: #033d5b; /* Color de fondo */
  z-index: 1; /* Índice Z para la superposición */
}

.amount-recollected {
  color: #cd0303; 
  font-size: 11px;
  font-weight: 610 !important;
}

.amount-recovered {
  color: #373d3fa8; 
  font-size: 11px;
  font-weight: 610 !important;
}

.amount-Total {
  color: #00a9ff; 
  font-size: 11px;
  font-weight: 610 !important;
}

.custom-card1 {
  transition: background-color 0.3s, transform 0.3s;
  overflow: hidden;
  padding: 0px;
}

.custom-card1:hover {
  background-color: rgba(216, 216, 216, 0.259); 
  transform: scale(1.05); 
}

.label-finance {
  color: #ffffff !important;
}

.card-validateFinance-E {
  height: 30px;
  width: 27px;
  background-color: rgb(17, 255, 0);
  color: #FFF;
  text-align: center;
  display: flex; 
  justify-content: center; 
  align-items: center; 
}

.card-validateFinance-C {
  height: 30px;
  width: 27px;
  background-color: rgb(255, 0, 0);
  color: #FFF;
  text-align: center;
  display: flex; 
  justify-content: center; 
  align-items: center; 
}

.card-validateFinance-TE {
  height: 30px;
  width: 27px;
  background-color: rgb(140, 0, 255);
  color: #FFF;
  text-align: center;
  display: flex;
  justify-content: center; 
  align-items: center; 
}

.card-validateFinance-TD {
  height: 30px;
  width: 27px;
  background-color: rgb(255, 217, 0);
  color: #FFF;
  text-align: center;
  display: flex; 
  justify-content: center; 
  align-items: center; 
}
.card-validateFinance-TC {
  height: 30px;
  width: 27px;
  background-color: rgb(255, 60, 0);
  color: #FFF;
  text-align: center;
  display: flex; 
  justify-content: center; 
  align-items: center; 
}

.table-wrapper-saldos {
  width: 100%;
  height: 350px; /* Altura de ejemplo */
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #f8d62b rgba(0, 0, 0, 0);
}

.table-wrapper-saldos table thead {
  position: -webkit-sticky; /* Safari... */
  position: sticky;
  top: 0;
  left: 0;
}




.card-validateFinance-MP {
  height: 30px;
  width: 27px;
  background-color: rgb(0, 72, 255);
  color: #FFF;
  text-align: center;
  display: flex; 
  justify-content: center;
  align-items: center; 
}

.card-validateFinance-na {
  background-color: rgb(198, 198, 198);
  color: #FFF;
  text-align: center;
  display: flex; 
  justify-content: center;
  align-items: center; 
}


.typeFilterDeposits, .InvalidFilterDeposits {
  align-items: center;
  background-color: #495057f7!important;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-align: center;
  height: 30px;
  transition: all 0.3s ease; 
}

.typeFilterDeposits:hover, .InvalidFilterDeposits:hover {
  background-color: #dcddde; 
  transform: scale(1.05); 
}


.typeFilterDeposits {
  background-color: #495057f7!important;
}

.InvalidFilterDeposits {
  background-color: #c2bebe !important;
}


.typeFilterDeposits:hover {
  background-color: #343a40; 
}


.badge-detail-Finance {
  font-size: 85%;
  font-weight: 600;
  line-height: 1;
  display: inline-block;
  padding: 0.35em 0.4em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
  border-radius: 0.21rem;
  background-color: #9c27b0 !important;
  border-radius: 5px !important;
  color: #FFF;
  /* width: 120px;
    height: 20px; */
    place-content: center;
}


.badge-detail-Finance-status {
  font-size: 95%;
  font-weight: 600;
  line-height: 1;
  display: inline-block;
  padding: 0.35em 0.4em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
  border-radius: 0.21rem;
  background-color: #10dfdb !important;
  border-radius: 5px !important;
  color: #FFF;
  place-content: center;
}

.badge-detail-Finance-cancel {
  font-size: 95%;
  font-weight: 600;
  line-height: 1;
  display: inline-block;
  padding: 0.35em 0.4em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
  border-radius: 0.21rem;
  background-color: #f17985 !important;
  border-radius: 5px !important;
  color: #FFF;
  place-content: center;
}


.table-wrapper-patient {
  width: 100%;
  height: 340px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #000000 rgba(0, 0, 0, 0);
}

.table-wrapper-patient table thead {
  position: -webkit-sticky; /* Safari... */
  position: sticky;
  top: 0;
  left: 0;
}

.badge-detail-Patient-NotPaid {
  font-size: 95%;
  font-weight: 600;
  line-height: 1;
  display: inline-block;
  padding: 0.35em 0.4em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
  border-radius: 0.21rem;
  background-color: #dc3545 !important;
  border-radius: 5px !important;
  color: #FFF;
  place-content: center;
}
.badge-detail-Patient-Paid {
  font-size: 95%;
  font-weight: 600;
  line-height: 1;
  display: inline-block;
  padding: 0.35em 0.4em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
  border-radius: 0.21rem;
  background-color: #46afe5  !important;
  border-radius: 5px !important;
  color: #FFF;
  place-content: center;
}

.badge-detail-Finance-Complement {
  font-size: 95%;
  font-weight: 600;
  line-height: 1;
  display: inline-block;
  padding: 0.35em 0.4em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
  border-radius: 0.21rem;
  background-color: #ff1b04 !important;
  border-radius: 5px !important;
  color: #FFF;
  place-content: center;
}

.table-wrapper-finance {
  width: 100%;
  height: 300px; /* Altura de ejemplo */
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #00294094 rgba(0, 0, 0, 0);
}

.table-wrapper-finance table thead {
  position: -webkit-sticky; /* Safari... */
  position: sticky;
  top: 0;
  left: 0;
}


















