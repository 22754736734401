@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.button-carta-Consentimiento {
  background-color: #09D4FC;
  border: none;
  color: white;
  /* padding: 10px 52px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 14px;
  font-weight: bold;
  width: 250px;
  height: 52px;
}

.button-eat-Consentimiento {
  background-color: #09D4FC;
  border: none;
  color: white;
  /* padding: 10px 52px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 26px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 14px;
  font-weight: bold;
  width: 300px;
  height: 65px;
}

.button-page-carta-Consentimiento {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border: 2px solid #09D4FC;
  border-radius: 20px;
  padding: 10px 32px;
}

.comentariosDinamicos {
  width: 100%;
  height: 160px;
  border: 2px solid #09D4FC;
  border-radius: 15px;
  font-size: 16pt
}




.button-carta-Consentimiento-Page2-vacio {
  background-color: #ffffff;
  border-color: #09D4FC;
  border-width: 3px;
  border-style: solid;
  color: #09D4FC;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 28px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 16px;
  font-weight: bold;
  width: 270px;
  height: 60px;
}



.button-carta-Consentimiento-Page2-noAplica {
  background-color: #09D4FC;
  border: none;
  color: white;
  padding: 10px 72px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 20px;
  font-weight: bold;

}




.button-carta-Consentimiento-Page4 {
  background-color: #09D4FC;
  border: none;
  color: white;

  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 22px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 25px;
  font-weight: bold;
  width: 200px;
  height: 50px;
}




.button-carta-Consentimiento-Page12-vacio {
  background-color: #ffffff;
  border-color: #09D4FC;
  border-width: 3px;
  border-style: solid;
  color: #09D4FC;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 22px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 16px;
  font-weight: bold;
  width: 210px;
  height: 45px;
}




.canva-key {
  border: 2px solid #09D4FC;
  background: #ffffff;
  border-radius: 25px;
}







.borde-Pruebas {
  border-top-left-radius: 10px
}


@media screen and (max-width: 4200px) {
  .label-Question {
    color: #000000;
    font-size: 36px;
    font-family: 'Montserrat', sans-serif;
  }


  .TimeEat {
    border: none;
    color: #46AFE5;
    font-size: 57px;
    font-family: helvetica;
    /* width: 339px; */
    border-radius: 21px;
    border: 3px solid #46AFE5;
    width: 470px;
    height: 140px;

  }

  /* Hour */
  .TimeEat[type=time]::-webkit-datetime-edit-hour-field {
    background-color: #46AFE5;
    border-radius: 19%;
    color: #fff;
    /* width: 200px;
    height: 80px;  */
    padding: 40px 12px;
  }

  /* Minute */
  input-[type=time]::-webkit-datetime-edit-minute-field {
    background-color: #ffffff;
    border-radius: 19%;
    padding: 19px 13px;
    color: #46AFE5;
  }

  /* AM/PM */
  .TimeEat[type=time]::-webkit-datetime-edit-ampm-field {
    background-color: #46AFE5;
    border-radius: 19%;
    color: #fff;
    padding: 19px 13px;
  }

  .label-Question-5 {
    color: #000000;
    font-size: 30px;
    font-family: 'Montserrat', sans-serif;
  }

  .estilotextarea {
    border: 2px solid #09D4FC;
    border-radius: 15px;
    font-size: 22pt;

  }

  .button-carta-Consentimiento-Page2-lleno {
    background-color: #09D4FC;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 16px;
    font-weight: bold;
    width: 270px;
    height: 60px;
  }

  .label-Question-6 {
    color: #000000;
    font-size: 30px;
    font-family: 'Montserrat', sans-serif;
  }

  .button-carta-Consentimiento-Page12-lleno {
    background-color: #09D4FC;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 16px;
    font-weight: bold;
    width: 220px;
    height: 50px;
  }

  .button-carta-Consentimiento-finalizar {
    background-color: #09D4FC;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 16px;
    font-weight: bold;
    width: 220px;
    height: 50px;
  }

  .label-Concentimiento-Informado {
    color: #000000;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
  }

  .button-carta-Repetir {
    background-color: #ffffff;
    border-color: #09D4FC;
    border-width: 3px;
    border-style: solid;
    color: #09D4FC;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 24px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 16px;
    font-weight: bold;
    width: 300px;
    height: 50px;
  }

  .button-carta-Consentimiento-Page14 {
    background-color: #09D4FC;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 21px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 16px;
    font-weight: bold;
    width: 300px;
    height: 50px;
  }

  .button-samplingForm {
    background-color: #09D4FC;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 18px;
    font-weight: bold;
    width: 300px;
    height: 50px;
  }

  .estilotextareaEncuestaCat {
    width: 1200px;
    height: 160px;
    border: 2px solid #09D4FC;
    border-radius: 15px;
    font-size: 16pt
  }

  .estilotextareaObservations {
    border: 2px solid #09D4FC;
    border-radius: 15px;
    font-size: 11pt;
    width: 270px;
    height: 170px;

  }


}

@media screen and (max-width: 1199.5px) {

  .label-Question {
    color: #000000;
    font-size: 36px;
    font-family: 'Montserrat', sans-serif;
  }


  .TimeEat {
    border: none;
    color: #46AFE5;
    font-size: 57px;
    font-family: helvetica;
    /* width: 339px; */
    border-radius: 21px;
    border: 3px solid #46AFE5;
    width: 470px;
    height: 140px;

  }

  /* Hour */
  .TimeEat[type=time]::-webkit-datetime-edit-hour-field {
    background-color: #46AFE5;
    border-radius: 19%;
    color: #fff;
    /* width: 200px;
    height: 80px;  */
    padding: 40px 12px;
  }

  /* Minute */
  input-[type=time]::-webkit-datetime-edit-minute-field {
    background-color: #ffffff;
    border-radius: 19%;
    padding: 19px 13px;
    color: #46AFE5;
  }

  /* AM/PM */
  .TimeEat[type=time]::-webkit-datetime-edit-ampm-field {
    background-color: #46AFE5;
    border-radius: 19%;
    color: #fff;
    padding: 19px 13px;
  }

  .label-Question-5 {
    color: #000000;
    font-size: 30px;
    font-family: 'Montserrat', sans-serif;
  }

  .estilotextarea {
    border: 2px solid #09D4FC;
    border-radius: 15px;
    font-size: 22pt
  }

  .label-Question-6 {
    color: #000000;
    font-size: 36px !important;
    font-family: 'Montserrat', sans-serif;
  }

  .label-Concentimiento-Informado {
    color: #000000;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;

  }

  .button-carta-Consentimiento-Page14 {
    background-color: #09D4FC;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 21px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 16px;
    font-weight: bold;
    width: 300px;
    height: 50px;
  }

  .button-samplingForm {
    background-color: #09D4FC;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 24px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 18px;
    font-weight: bold;
    width: 310px;
    height: 55px;
  }

  .estilotextareaObservations {
    border: 2px solid #09D4FC;
    border-radius: 15px;
    font-size: 16pt;
    width: 600px;
    height: 80px;

  }






}

/* size tablet */

@media screen and (max-width: 1130px) {

  .label-Question {
    color: #000000;
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
  }


  .TimeEat {
    border: none;
    color: #46AFE5;
    font-size: 50px;
    font-family: helvetica;
    /* width: 339px; */
    border-radius: 21px;
    border: 3px solid #46AFE5;
    width: 400px;
    height: 90px;

  }

  /* Hour */
  .TimeEat[type=time]::-webkit-datetime-edit-hour-field {
    background-color: #46AFE5;
    border-radius: 19%;
    color: #fff;
    /* width: 200px;
    height: 80px;  */
    padding: 40px 12px;
  }

  /* Minute */
  input-[type=time]::-webkit-datetime-edit-minute-field {
    background-color: #ffffff;
    border-radius: 19%;
    padding: 19px 13px;
    color: #09D4FC;
  }

  /* AM/PM */
  .TimeEat[type=time]::-webkit-datetime-edit-ampm-field {
    background-color: #46AFE5;
    border-radius: 19%;
    color: #fff;
    padding: 19px 13px;
  }

  .label-Question-5 {
    color: #000000;
    font-size: 23px !important;
    font-family: 'Montserrat', sans-serif;
  }

  .estilotextarea {
    border: 2px solid #09D4FC;
    border-radius: 15px;
    font-size: 16pt;
    right: 40px !important;
  }


  .button-carta-Consentimiento-Page2-lleno {
    background-color: #09D4FC;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 26px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 16px;
    font-weight: bold;
    width: 270px;
    height: 60px;
  }

  .label-Question-6 {
    color: #000000;
    font-size: 31px !important;
    font-family: 'Montserrat', sans-serif;
  }

  .label-Question-6- {
    color: #000000;
    font-size: 28px;
    font-family: 'Montserrat', sans-serif;
  }

  .button-carta-Consentimiento-Page12-lleno {
    background-color: #09D4FC;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 16px;
    font-weight: bold;
    width: 220px;
    height: 50px;
  }

  .button-carta-Consentimiento-finalizar {
    background-color: #09D4FC;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 16px;
    font-weight: bold;
    width: 220px;
    height: 50px;
  }

  .label-Concentimiento-Informado {
    color: #000000;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;

  }

  .button-carta-Repetir {
    background-color: #ffffff;
    border-color: #09D4FC;
    border-width: 3px;
    border-style: solid;
    color: #09D4FC;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 24px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 16px;
    font-weight: bold;
    width: 300px;
    height: 50px;
  }

  .button-carta-Consentimiento-Page14 {
    background-color: #09D4FC;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 21px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 16px;
    font-weight: bold;
    width: 300px;
    height: 50px;
  }

  .button-samplingForm {
    background-color: #09D4FC;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 18px;
    font-weight: bold;
    width: 310px;
    height: 55px;
  }

  .estilotextareaEncuestaCat {
    width: 900px;
    height: 120px;
    border: 2px solid #09D4FC;
    border-radius: 15px;
    font-size: 13pt
  }

  .estilotextareaObservations {
    border: 2px solid #09D4FC;
    border-radius: 15px;
    font-size: 10pt;
    width: 210px;
    height: 180 !important;

  }





}

@media screen and (max-width: 575.99px) {}





.comentFlebotomista {

  font-size: 12px;
  padding: 0px;
  overflow-x: auto;
  overflow-y: auto;
  border: solid 1px;
  border-color: #a5c7e3;
  width: 380px;
  height: 240px;
  background-color: #fafafa;
  border-color: #a4e4dd;
}

.comentFlebotomista2 {
  font-size: 12px;
  padding: 0px;
  overflow-x: auto;
  overflow-y: auto;
  border: solid 1px;
  border-color: #a5c7e3;
  width: 360px;
  height: 200px;
  background-color: #fafafa;
  border-color: #a4e4dd;
  color: #23766c !important;
  font-size: 14px;
}

.comentFlebotomistaSampling1 {
  font-size: 12px;
  padding: 0px;
  overflow-x: auto;
  overflow-y: auto;
  border: solid 1px;
  border-color: #a5c7e3;
  width: 360px;
  height: 270px;
  background-color: #fafafa;
  border-color: #a4e4dd;
  color: #23766c !important;
  font-size: 14px;
}

.LoadingSampling1 {

  font-size: 12px;
  padding: 0px;
  overflow-x: auto;
  overflow-y: auto;
  border: solid 1px;
  border-color: #a5c7e3;
  width: 400px;
  height: 250px;
  background-color: #fafafa;
  border-color: #a4e4dd;
}


.toma-img {
  text-align: right;
  cursor: pointer;
  display: none;
  position: fixed;
  bottom: 190px;
  z-index: 5;
  color: transparent;
  background: transparent;
  border-color: transparent;
  padding: 7px;
  box-shadow: 0 0 10px transparent;
  /* border-radius: 100%; */
  opacity: 10;
  transition: all 0.3s ease;
}

.tap-top-cardToma {
  text-align: -webkit-right;
  cursor: pointer;
  display: none;

  color: transparent;
  background: transparent;
  border-color: transparent;
  position: fixed;
  bottom: 110px;
  right: 30px;
  z-index: 5;
  border: none;
  padding: 7px;
  opacity: 0.5;
  transition: all 0.3s ease;
}


.imgSampleStars {
  width: 150px !important;
}