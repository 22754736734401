.Obser {
  height: 100px !important;
  width: 570px !important;
}

.labeldate {
  height: 40px;
  width: 20;
}

.buttonActions {
  width: 160px;
  height: 30px;
  
}

.iconcolorQuoter{
  color: #0079C7;
}

.gridQuoter {
  height: 180px !important;
  width: 550px !important;
}

.borderColo{
  border-color: #005a94;
  border-width: 2px;
  border-style: solid;
}
.dx-g-bs4-fixed-cell, .dx-g-bs4-fixed-group-cell{
  z-index: 0 !important;
}






@media screen and (max-width: 1460px) {
     
  .labelInfoAnalyte{
    font-size: 13px !important;
  }

  .cardQuoter{
    height: 130px !important;
    border-radius: 10% !important
  }

  .sizeCardQuoter{
    height: 130px  !important;
    background-color: #0079C7;
  }
  .labelquoter{
    font-size: 16px !important;
    color: #fff;
  }
  .labelquoterP{
    font-size: 20px !important;
    color: #fff;
  }

  .imgQuoter{
    height: 70px !important;
  }


}

@media screen and (max-width: 1199.5px) {
     
  .labelInfoAnalyte{
    font-size: 13px !important;
  }
  .cardQuoter{
    height: 130px !important
  }
  .sizeCardQuoter{
    height: 130px  !important;
    background-color: #0079C7;

  }
  .labelquoter{
    font-size: 16px !important;
    color: #fff;
  }
  .labelquoterP{
    font-size: 20px !important;
    color: #fff;
  }
  .imgQuoter{
    height: 70px !important;
  }

  


}

@media screen and (max-width: 1130px){
     
  .labelInfoAnalyte{
    font-size: 11px !important;
  }
  .cardQuoter{
    height: 130px !important
  }
  .sizeCardQuoter{
    height: 130px  !important;
  }
  .labelquoter{
    font-size: 13px !important;
    color: #fff;
  }
  .labelquoterP{
    font-size: 20px !important;
    color: #fff;
  }
  .imgQuoter{
    height: 50px !important;
  }
}



@media screen and (max-width: 575.99px){
   
  .labelInfoAnalyte{
    font-size: 11px !important;
  }
  .cardQuoter{
    height: 130px !important
  }
  .sizeCardQuoter{
    height: 130px  !important;
  }
  .labelquoter{
    font-size: 13px !important;
    color: #fff;
  }
  .imgQuoter{
    height: 140px !important;
  }



  
}






